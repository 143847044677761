body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.underscore{
  height:4px;
  background-color: red;
  width:5rem;
  margin-bottom: 15px;
  border-radius: 4px;
}

.sub_underscore{
  height:4px;
  border-radius: 4px;
  background-color: rgba(42,22,243);
  width:4rem;
  margin-bottom: 15px;
}
.sub_underscore1{
  height:4px;
  border-radius: 4px;
  background-color: rgb(19, 212, 2);
  width:4rem;
  margin-bottom: 15px;
}
 .f2f2f2{
   background: #f2f2f2;
 }
 .quick_links li{
   list-style: none;
   margin: 0px;
 }
 .quick_links a{
   color:#fff;
   text-decoration: none;
   margin-bottom: 8px;
   display: block;
 }
 .border_dots{
   border-top:1px dotted #fff;
   margin-top: 15px;
 }